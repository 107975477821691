<template>
  <NuxtLayout name="404">
    <div class="text-4xl grid gap-3">
      <p>Hey, so you're interested in funding Ceasy?</p>
      <p>Then please <span @click="sendEmail" class="underline cursor-pointer">send me an e-mail</span> and tell me why
        you would be a good investor!</p>
      <p class="text-sm" :class="hideNotice ? 'invisible' : ''">{{ notice }}</p>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import { promiseTimeout, useWindowFocus } from '@vueuse/core'
const focused = useWindowFocus()

const notice = ref(atob('SW4gY2FzZSB5b3VyIGVtYWlsIHByb2dyYW1tZSBkaWQgbm90IG9wZW4gYXV0b21hdGljYWxseSwgcGxlYXNlIGNvbnNpZGVyIHRvIG1hbnVhbGx5IHNlbmQgYW4gZW1haWwgdG8gYmVuQGNlYXN5LmNvbXBhbnk='))
const hideNotice = ref(true)
const sendEmail = async () => {
  hideNotice.value = true
  window.location.href = atob('bWFpbHRvOmJlbkBjZWFzeS5jb21wYW55P3N1YmplY3Q9W0ZVTkRJTkclMjBSRVFVRVNUXSUyMEklMjB3b3VsZCUyMGxpa2UlMjB0byUyMGludmVzdCUyMGluJTIwQ2Vhc3k=');
  await promiseTimeout(500)
  if (focused.value) {
    hideNotice.value = false
  }
}
</script>
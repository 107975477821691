import revive_payload_client_RkPCfvwTWU from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_F3X4DH8Kjq from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_clMuOuPEHM from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Wn7FzVnyTm from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_ZTFQmxdqYS from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_rK6RknZKSk from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_fb1bq0MX7g from "/vercel/path0/node_modules/.pnpm/nuxt@3.8.2_vite@4.5.0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
export default [
  revive_payload_client_RkPCfvwTWU,
  unhead_F3X4DH8Kjq,
  router_clMuOuPEHM,
  payload_client_Wn7FzVnyTm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZTFQmxdqYS,
  chunk_reload_client_rK6RknZKSk,
  check_outdated_build_client_fb1bq0MX7g
]
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as pitchdeck632huDn9Z0Meta } from "/vercel/path0/pages/t/[trackingId]/pitchdeck.vue?macro=true";
import { default as summaryURtwvDhyZrMeta } from "/vercel/path0/pages/t/[trackingId]/summary.vue?macro=true";
export default [
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: pitchdeck632huDn9Z0Meta?.name ?? "t-trackingId-pitchdeck",
    path: pitchdeck632huDn9Z0Meta?.path ?? "/t/:trackingId()/pitchdeck",
    meta: pitchdeck632huDn9Z0Meta || {},
    alias: pitchdeck632huDn9Z0Meta?.alias || [],
    redirect: pitchdeck632huDn9Z0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/t/[trackingId]/pitchdeck.vue").then(m => m.default || m)
  },
  {
    name: summaryURtwvDhyZrMeta?.name ?? "t-trackingId-summary",
    path: summaryURtwvDhyZrMeta?.path ?? "/t/:trackingId()/summary",
    meta: summaryURtwvDhyZrMeta || {},
    alias: summaryURtwvDhyZrMeta?.alias || [],
    redirect: summaryURtwvDhyZrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/t/[trackingId]/summary.vue").then(m => m.default || m)
  }
]